<template>
  <div>
    <app-iqa v-if="courseChosen === 'iqa'"></app-iqa>
    <app-aa v-if="courseChosen === 'aa'"></app-aa>
    <app-ac v-if="courseChosen === 'ass'"></app-ac>
  </div>
</template>

<script>
import iqa from '@/components/resources/IQA.vue';
import aa from '@/components/resources/AssessingAward.vue';
import ac from '@/components/resources/AssessingCertificate.vue';

export default {
  name: 'Resources',
  components: {
    appIqa: iqa,
    appAa: aa,
    appAc: ac,
  },
  computed: {
    courseChosen() {
      return this.$store.getters.courseChosen;
    },
  },
};
</script>
