<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>Assessing Certificate Resources</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <ul>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+1+Getting+started.mov" target="_blank">
                VLE 1 Getting Started
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+2+Add+and+Delete+Evidence.mov" target="_blank">
                VLE 2 Add and Delete Evidence
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+3+Add+and+Delete+Coursework.mov" target="_blank">
                VLE 3 Add and Delete Coursework
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+4+Resources.mov" target="_blank">
                VLE 4 Resources
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+5+Messages.mov" target="_blank">
                VLE 5 Messages
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/Assessing_Knowledge_Handbook_TAQA.pdf" target="_blank">
                Assessing Knowledge TAQA Handbook
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/Assessing_Certificate_Individual_Learning_Plan_ILP.docx" target="_blank">
                Assessing Certificate Individual Learning Plan (ILP)
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/Learner_overview.doc" target="_blank">
                Learner overview
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/TAQA_Witness_statement_for_assessors.doc" target="_blank">
                TAQA Witness statement for assessors
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/Worksheet_1_The_Assessment_process_My_roles_and_responsibilities.pdf" target="_blank">
                Worksheet 1 The Assessment process -  My roles and responsibilities
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/Worksheet_2_Functions_and_types_of_Assessment.pdf" target="_blank">
                Worksheet 2 Functions and types of Assessment
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/worksheet_three_assessment_methods_v2.pdf" target="_blank">
                Worksheet 3 Assessment methods
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/Worksheet_4_Risks_involved_in_assessment.pdf" target="_blank">
                Worksheet 4 Risks involved in assessment
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/Worksheet_5_Factors_to_consider_when_planning.pdf" target="_blank">
                Worksheet 5 Factors to consider when planning
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/Worksheet_6_Quality_assurance_and_disputes.pdf" target="_blank">
                Worksheet 6 Quality assurance and disputes
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/Worksheet_7_Legislation_and_regulatory_requirements.pdf" target="_blank">
                Worksheet 7  Legislation and regulatory  requirements
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/TAQA_L3_Assessing_slides.pdf" target="_blank">
                TAQA L3 Assessing slides
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssessingCertificateResources',
};
</script>
